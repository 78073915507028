export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [6,[2]],
		"/(landing)/about": [15,[4],[5]],
		"/(app)/auth/error": [9,[2]],
		"/(landing)/changelog": [16,[4],[5]],
		"/(app)/explore": [10,[2]],
		"/(landing)/extension": [17,[4],[5]],
		"/(landing)/login": [18,[4],[5]],
		"/(landing)/pricing": [19,[4],[5]],
		"/(landing)/privacy-policy": [20,[4],[5]],
		"/(landing)/roadmap": [21,[4],[5]],
		"/(app)/search/[type]": [11,[2]],
		"/(landing)/signup": [22,[4],[5]],
		"/(app)/sync-status": [12,[2,3]],
		"/(landing)/terms-of-service": [23,[4],[5]],
		"/(app)/trash": [13,[2]],
		"/(landing)/use-cases/bookmark-manager": [24,[4],[5]],
		"/(app)/user": [14,[2]],
		"/(app)/[...slug=collection]": [~7,[2]],
		"/(app)/[...slug=profile]": [~8,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';